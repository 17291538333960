import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, catchError, tap } from 'rxjs/operators';
import{environment} from "../../../../environments/environment";
import { UtilService } from '../util.service';
import { of, Observable } from 'rxjs';

@Injectable()
export class ClientService {

  constructor(
    private http: HttpClient,
    private util: UtilService
  ) {
  }

  getClients(): Observable<any> {
    const url = environment.apiURL+"/partner/clientShortNames";
    const dataStoreKey = 'clients';
    let storedDataStr = localStorage.getItem(dataStoreKey);
    if(storedDataStr != null){
      return of(JSON.parse(storedDataStr));
    }

    return this.http.post(url, {})
      .pipe(tap((response: any) => {
        localStorage.setItem(dataStoreKey, JSON.stringify(response))
      }))
      .pipe(
        catchError(this.util.handleAPIError)
      );
  }

}
