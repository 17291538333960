import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import{environment} from "environments/environment"

const LANGS_STR = "Langs"
@Injectable()
export class DataSetService {
    constructor(
        private http: HttpClient,
    ) {
    }

    GetAllLangCodes(): Observable<any> {
        let codes =  JSON.parse(localStorage.getItem(LANGS_STR));

        if (codes && codes.langCodes) {
            return of(codes);
        }
        const url =environment.apiURL+"/dataset/getAllLangCodes";

        return this.http.get(url).pipe(
            tap((res : any)=> {
                if (res.succeeded) this.putAllLangCodesToLocalStorage(res);
            }),
            catchError(this.handleError)
        );
    }

    putAllLangCodesToLocalStorage(codes) {
        localStorage.setItem(LANGS_STR, JSON.stringify(codes));
    }


    private handleError(error: Response | any) {
        console.error('ApiService::handleError', error);
        return Observable.throw(error);
    }
}
