import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import{environment} from "environments/environment";
import {a} from '@angular/core/src/render3';

@Injectable()
export class MyTaskTemplatesService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getAll(projectShortName): Observable<any> {
        const url = environment.apiURL+"/mytaskstemplates/listLangVersions";
        const params = {
            "projectShortName": projectShortName
        };
        return this.http.post(url, params)
            .pipe(
                catchError(this.handleError)
            );
    }

    create(projectShortName, newMyTask: any): any {
        const url = environment.apiURL+"/mytaskstemplates/createOrUpdate";
        const params = {
            "projectShortName": projectShortName,
            "templateLangCode": newMyTask.languageCode,
            "sectionAbout": newMyTask.aboutEditorData,
            "sectionPrep": newMyTask.preparationEditorData,
            "sectionHelp": newMyTask.helpEditorData
        };
        return this.http.post(url, params)
            .pipe(
                catchError(this.handleError)
            );
    }

    get(projectShortName: string, langCode: any): Observable<any> {
        const url = environment.apiURL+"/mytaskstemplates/get";
        const params = {
            "projectShortName": projectShortName,
            "templateLangCode": langCode
        };
        return this.http.post(url, params)
            .pipe(
                catchError(this.handleError)
            );
    }

    delete(langCode: any, projectShortName: string) {
        const url = environment.apiURL+"/mytaskstemplates/delete";
        const params = {
            "projectShortName": projectShortName,
            "templateLangCode": langCode
        };
        return this.http.post(url, params)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: Response | any) {
        console.error('ApiService::handleError', error);
        return Observable.throw(error);
    }

    getMytaskPreviewData(projectShortName: string, langCode: any): Observable<any> {
        const url = environment.apiURL+"/mytasks/preview";
        const params = {
            "projectShortName": projectShortName,
            "langCode": langCode
        };
        return this.http.post(url, params)
            .pipe(
                catchError(this.handleError)
            );
    }
}
