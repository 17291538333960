import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "SAReportStatus",
  pure: false
})
export class SAReportStatusPipe implements PipeTransform {

  transform(status: string): string {
    var LITERALS = {
      'INIT': 'Processing',
      'REQUESTED': 'Requested',
      'RENDERED': 'Generated'
    }

    return (LITERALS[status] || status)
  }
}
