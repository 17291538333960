import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import{environment} from "environments/environment";
import { UtilService } from './util.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class BulkUploadService {

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private datePipe: DatePipe
  ) {

  }

  uploadCandidates(projectShortName, language, reportsToAttachTo, candidates,
                   completedByTimezone, completedByDate, jobTitle, costCentreName) {
    const url = environment.apiURL+"/bulk/upload/candidates";

    return this.http.post(url,
      {
        "bulkUploadInfo": {
          "projectShortName": projectShortName,
          "myTaskLangCode": language,
          "reportsToAttach": reportsToAttachTo,
          //new fields
          // "completedByDate": this.datePipe.transform(completedByDate,'dd/MM/yyyy'),
          "completedByDate": completedByDate,
          "completedByTimezone": completedByTimezone,
          "jobTitle": jobTitle,
          "costCentreName": costCentreName
        },
        "bulkUploadCandidateInfo": candidates
      }
    ).pipe(
      catchError(this.utilService.handleAPIError)
    );
  }

  getBulkUploadLogs(projectShortName, timeZone) {
    const url = environment.apiURL+"/bulk/upload/logs/project";

    return this.http.post(url,
      {
        "projectShortName": projectShortName,
        "timeZone": timeZone
      }
    ).pipe(
      catchError(this.utilService.handleAPIError)
    );
  }

  getLogDetails(projectShortName, key, status, pageNumber, pageSize) {
    const url = environment.apiURL+"/bulk/upload/logs/candidates";

    return this.http.post(url,
      {
        "projectShortName": projectShortName,
        "projectLogName": key,
        "succeeded": status == 'failed' ? false: true,
        "pagination": {
          "currentPageNo": pageNumber,
          "recordsPerPage": pageSize
        }
      }
    ).pipe(
      catchError(this.utilService.handleAPIError)
    );
  }

}
