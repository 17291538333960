import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { Injectable } from '@angular/core';

import { AppComfirmVTwoComponent } from './app-confirm.component';

interface confirmData {
  title?: string,
  message?: string,
  confirmActionText?: string,
  dialogWidth?: string
}

@Injectable()
export class AppConfirmVTwoService {

  constructor(private dialog: MatDialog) { }

  public confirm(data: confirmData = {}): Observable<boolean> {
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Are you sure?';
    let dialogRef: MatDialogRef<AppComfirmVTwoComponent>;
    dialogRef = this.dialog.open(AppComfirmVTwoComponent, {
      width: data.dialogWidth || "400px",
      disableClose: true,
      data: {
        title: data.title,
        message: data.message,
        confirmActionText: data.confirmActionText
      }
    });
    return dialogRef.afterClosed();
  }
}