import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import{environment} from "environments/environment";
import { CookieService } from './cookie.service';

@Injectable()
export class ReportService {

    constructor(
        private http: HttpClient,
        private cookieService: CookieService
    ) {

    }

    getAllCustomisedReports(projectShortName: string) {

        let custReportsCookieName = 'c_r_for_' + projectShortName

        let custReports = this.cookieService.getCookie(custReportsCookieName);
        if (custReports != null && custReports != '') {
            return of(JSON.parse(custReports));
        }

        return this.http.post(environment.apiURL+"/custreport/list",
            { projectShortName: projectShortName }
        )
            .pipe(tap((response: any) => {
                this.cookieService.createCookie(custReportsCookieName, JSON.stringify(response), 30);
            }))
            .pipe(
                catchError(this.handleError)
            );
    }

    getProjectDetailByShortName(projectShortName: string) {
      return this.http.post(environment.apiURL+"/project/getProjectDetailByShortName",
        { projectShortName: projectShortName }
      )
        .pipe(tap((response: any) => {
          // this.cookieService.createCookie(custReportsCookieName, JSON.stringify(response), 30);
        }))
        .pipe(
          catchError(this.handleError)
        );
    }


  getAllCustomisedReportal2Reports(projectId: string) {

    // let custReportsCookieName = 'c2_r2_for_' + projectId;
    //
    // let custReports = this.cookieService.getCookie(custReportsCookieName);
    // if (custReports != null && custReports != '') {
    //   return of(JSON.parse(custReports));
    // }

    return this.http.get(environment.apiURL + '/rePortal/listAllAttachedReportsInProject/' + projectId)
      .pipe(tap((response2: any) => {
        // this.cookieService.createCookie(custReportsCookieName, JSON.stringify(response2), 30);
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

  listAllAttachedReportsInProjectWithMeritListConfiguration(projectId: string) {
    return this.http.get(environment.apiURL + '/rePortal/listAllAttachedReportsInProjectWithMeritListConfiguration/' + projectId)
      .pipe(tap((response2: any) => {
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

    private handleError(error: Response | any) {
        console.error('ApiService::handleError', error);
        return Observable.throw(error);
    }

    addCandidates(candidates: any, projectShortName: string, reportShortName: string, reportLangCode: string): any {
        const url = environment.apiURL+"/custreport/allocateCandidates";
        // console.log(candidates);
        let data = {
            projectShortName: projectShortName,
            reportShortName: reportShortName,
            reportLangCode: reportLangCode,
            candidateEmails: candidates.map(c => c.emailAddress),
        }

        return this.http.post(url, data).pipe(
            catchError(this.handleError)
        );
    }

  attachCandidateToReport(candidates: any, projectId: string, reportId: string): any {
    const url = environment.apiURL+"/reportal2Pipeline/attachCandidateToReport";
    // console.log(candidates);
    let data = {
      projectId: projectId,
      reportId: reportId,
      candidateList: candidates
    };
    return this.http.post(url, data).pipe(
      catchError(this.handleError)
    );
  }

    addCandidatesMock(candidates: any, projectShortName: string, reportShortName: string, reportLangCode: string): any {
        return new Observable((observer) => {
            var data = {
                "internalErrorMessage": null,
                "succeeded": true,
                "newToken": "xxxxxxxxxxxxxxx", //auto renewed token

                //message details
                "successCandidateCount": 2,
                "failureCandidateCount": 3,
                "messageDetails": [{
                    "xxx1@xxx.com": "OK",
                    "xxx2@xxx.com": "OK",
                    "xxx3@xxx.com": "Invalid Candidate Email Address for current project",
                    "xxx4@xxx.com": "Candidate Already in the report",
                    "xxx5@xxx.com": "Candidate Already in the report"
                }
                ],
                "reportsInfo":
                    [
                        {
                            //candidate information
                            "candidateFirstName": "xxx",
                            "candidateLastName": "xxx1",
                            "candidateEmail": "xxx1@xxx.com",
                            //UTC, need to convert to client browser's time zone!
                            "generatedTimestamp": 1544082077,
                            //the url to download the report, READY to download
                            "downloadUrl": "https://......",
                            "rendered": false    //whether the report is ready
                        },
                        {
                            //candidate information
                            "candidateFirstName": "xxx",
                            "candidateLastName": "xxx1",
                            "candidateEmail": "xxx1@xxx.com",
                            //UTC, need to convert to client browser's time zone!
                            "generatedTimestamp": 1544088043,
                            //the url to download the report, READY to download
                            "downloadUrl": "https://......",
                            "rendered": true    //whether the report is ready
                        }
                    ]
            };
            observer.next(data);
            observer.complete();
        })
    }

    getCandidatesForProjectReport(projectShortName, reportShortName, reportLangCode, filterText, filterStatus, sortBy, isSortAsc, pageSize, pageNumber): any {
        return this.http.post(environment.apiURL+"/custreport/listCandidatesForProjectReport",
            {
                "projectShortName": projectShortName,
                "reportShortName": reportShortName,
                "reportLangCode": reportLangCode,
                "filterText": filterText,
                "status": filterStatus,
                "sortByField": sortBy,
                "sortByAsc": isSortAsc,
                "pagination":
                {
                    "currentPageNo": pageNumber || 0,
                    "recordsPerPage": pageSize || 50
                }
            }
        );
    }

  getCandidatesForReportal2ProjectReport(projectId, reportId, pageSize, pageNumber, sortByAsc, sortByField, filterText, status, filterType): any {
    return this.http.post(environment.apiURL+"/custreport/listCandidatesForReportal2ProjectReport",
      {
        "projectId": projectId,
        "reportId": reportId,
        "pagination":
          {
            "currentPageNo": pageNumber || 0,
            "recordsPerPage": pageSize || 50
          },
        "sortByAsc": sortByAsc,
        "sortByField": sortByField,
        "filterText": filterText,
        "status": status,
        "filterType": filterType
      }
    );
  }

  getCandidatesForReportal2Project(projectId, pageSize, pageNumber, sortByAsc, sortByField, filterText, status): any {
    return this.http.post(environment.apiURL+"/custreport/listCandidatesForReportal2Project",
      {
        "projectId": projectId,
        "pagination":
          {
            "currentPageNo": pageNumber || 0,
            "recordsPerPage": pageSize || 50
          },
        "sortByAsc": sortByAsc,
        "sortByField": sortByField,
        "filterText": filterText,
        "status": status
      }
    );
  }

    getCandidateReportStatus(partnerShortName, projectShortName, reportShortName, reportLangCode,
        candidateEmail, configurationVersion, rawDataVersion, vmVersion): Observable<any> {

        return this.http.post(environment.apiURL+"/custreport/status",
            {
                "partnerShortName": partnerShortName,
                "projectShortName": projectShortName,
                "reportLangCode": reportLangCode,
                "reportShortName": reportShortName,
                "requestCustReport":
                {
                    "candidateEmail": candidateEmail,
                    "configurationVersion": configurationVersion,
                    "rawDataVersion": rawDataVersion,
                    "vmVersion": vmVersion
                }
            }
        );
    }

    getCandidatesForProjectReportMock(projectShortName, reportShortName, reportLangCode, filterText, filterStatus, sortBy, isSortAsc): any {
        return new Observable((observer) => {
            var data = {
                "internalErrorMessage": null,
                "succeeded": true,
                "newToken": "xxxxxxxxxxxxxxx", //auto renewed token

                "reportsInfo":
                    [
                        {
                            //candidate information
                            "candidateFirstName": "xxx",
                            "candidateLastName": "xxx1",
                            "candidateEmail": "xxx1@xxx.com",
                            //UTC, need to convert to client browser's time zone!
                            "generatedTimestamp": 1544082077,
                            //the url to download the report, READY to download
                            "downloadUrl": "https://......",
                            "reportStatus": "Ready"    //report status
                        },
                        {
                            //candidate information
                            "candidateFirstName": "xxx",
                            "candidateLastName": "xxx1",
                            "candidateEmail": "xxx1@xxx.com",
                            //UTC, need to convert to client browser's time zone!
                            "generatedTimestamp": 1544088043,
                            //the url to download the report, READY to download
                            "downloadUrl": "https://......",
                            "reportStatus": "Not Available"    //report status
                        }
                    ],
                //*** PLEASE NOTE: pagination will ONLY be provided if the filter is empty (NULL)
                //*** if the filter is non-empty, max records retrieved is 50 and status toggling is ignored!
                //*** frontend just need to hide the pagination and display the actual record number returned ONLY!
                "pagination":
                {
                    "currentPageNo": 0,  //page no starting from 0
                    "recordsPerPage": 50, //how many records for each page
                    "totalRecords": 600, //the total number of reports, use it wisely to display the pagination bar :)
                    "lastPage": false     //indicate whether it is a last page or not
                }
            };
            observer.next(data);
            observer.complete();
        })
    }

    getProjCandInstrumentReports(myTaskHash, instrumentId) {
        const url = environment.apiURL+"/report/sa/getProjCandInstrumentReports";

        return this.http.post(url, {
            "mytaskHash": myTaskHash,
            "instrumentTemplateId": instrumentId
        });
    }

    generateSecondaryReport(mytaskHash, projectInstrumentId, reportId, normId) {
        const url = environment.apiURL+"/report/sa/getSecondaryReport";

        let reportParams = {
            "sa2ndReportRequestDTOList": [
                {
                    "mytaskHash": mytaskHash,
                    "projectInstrumentId": projectInstrumentId,
                    "reportId": reportId,
                    "normId": normId
                }
            ]
        }

        return this.http.post(url, reportParams);
    }


  getCompositeConfiguration(projectId, reportId) {
      const url = environment.apiURL + '/meritList/getCompositeConfiguration';
      const params = {
        'projectId': projectId,
        'reportId': reportId
      };
    return this.http.post(url, params);
  }

  // getReportComposites(reportId) {
  //   const url = environment.reportal2URL + '/report/getReportComposites';
  //   const params = {
  //     'reportId': reportId
  //   };
  //   return this.http.post(url, params);
  // }

  // getMeritListPresentationRules(projectId): Observable<any> {
  //   const url = environment.reportal2URL+"/meritList/getMeritListPresentationRules";
  //   return this.http.post(url, {
  //     "projectId": projectId
  //   }).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  getMeritListPresentationRulesName(projectId): Observable<any> {
    const url = environment.apiURL+"/meritList/getMeritListPresentationRulesName";
    return this.http.post(url, {
      "projectId": projectId
    }).pipe(
      catchError(this.handleError)
    );
  }

  getPageUpNRReport(hash) {
    return this.http.get(environment.apiURL + '/myreports/pageup/nr/' + hash, {
      "reportProgress": true,
      "responseType": "json",
      headers: {
        'show-loader': 'false'
      }
    })
      .pipe(tap((response: any) => {
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

  getPageUpSAReport(hash) {
    return this.http.get(environment.apiURL + '/myreports/pageup/sa/' + hash, {
      "reportProgress": true,
      "responseType": "json",
      headers: {
        'show-loader': 'false'
      }
    })
      .pipe(tap((response: any) => {
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

  getNetResultNRReport(hash) {
    return this.http.get(environment.apiURL + '/myreports/netresult/nr/' + hash, {
      "reportProgress": true,
      "responseType": "json",
      headers: {
        'show-loader': 'true'
      }
    })
      .pipe(tap((response: any) => {
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

  getNetResultSAReport(hash) {
    return this.http.get(environment.apiURL + '/myreports/netresult/sa/' + hash, {
      "reportProgress": true,
      "responseType": "json",
      headers: {
        'show-loader': 'true'
      }
    })
      .pipe(tap((response: any) => {
      }))
      .pipe(
        catchError(this.handleError)
      );
  }
}
