import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { PublicGuard } from './shared/services/auth/public.gauard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'candidates/list',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [PublicGuard],
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: './views/sessions/sessions.module#SessionsModule',
        data: { title: 'Session' }
      },
      {
        path: 'myreports',
        loadChildren: './views/pageup/pageup.module#PageupModule',
        data: { title: 'PageUp', breadcrumb: 'PageUp'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'candidates',
        loadChildren: './views/candidates/candidates.module#CandidatesModule',
        data: { title: 'Candidates', breadcrumb: 'CANDIDATES'}
      },
      {
        path: 'projects',
        loadChildren: './views/projects/projects.module#ProjectsModule',
        data: { title: 'Projects', breadcrumb: 'PROJECTS'}
      },
      {
        path: 'cost-centres',
        loadChildren: './views/cost-centres/cost-centres.module#CostCentresModule',
        data: { title: 'Cost Centres', breadcrumb: 'Cost centres'}
      },
      {
        path: 'data-extracts',
        loadChildren: './views/data-extracts/data-extracts.module#DataExtractsModule',
        data: { title: 'Data Extracts', breadcrumb: 'Data Extracts'}
      },
      {
        path: 'notifications',
        loadChildren: './views/notifications/notifications.module#NotificationsModule',
        data: { title: 'Notifications', breadcrumb: 'Notifications'}
      },
      {
        path: 'users',
        loadChildren: './views/auth/auth.module#AuthModule',
        data: { title: 'Users', breadcrumb: 'Users'}
      },
      {
        path: 'project-list',
        loadChildren: './views/projects-newlayout/projects-newlayout.module#ProjectsNewlayoutModule',
        data: { title: 'Projects', breadcrumb: 'Projects'}
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

