import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, catchError, tap } from 'rxjs/operators';
import { UtilService } from '../util.service';
import { of, Observable } from 'rxjs';
import{environment} from "../../../../environments/environment";

@Injectable()
export class CostCentreService {

  constructor(
    private http: HttpClient,
    private util: UtilService
  ) {
  }

  getAll(): Observable<any> {
    const url = environment.apiURL+"/costcentre/list";

    return this.http.post(url, {})
      .pipe(
        catchError(this.util.handleAPIError)
      );
  }


  createOrUpdate(costCentreData) {
    const url = environment.apiURL+"/costcentre/createOrUpdate";

    return this.http.post(url, costCentreData)
      .pipe(
        catchError(this.util.handleAPIError)
      );
  }

  delete(costCentreShortName) {
    const url = environment.apiURL+"/costcentre/delete";
    return this.http.post(url, {
      shortName: costCentreShortName
    })
      .pipe(
        catchError(this.util.handleAPIError)
      );
  }

  getProjectsForCostCentre(shortName) {
    const url = environment.apiURL+"/costcentre/list/projects";

    return this.http.post(url, {
      "costCentreShortName": shortName
    })
      .pipe(
        catchError(this.util.handleAPIError)
      );
  }

  updateCostCentreOfProject(projectShortName: any, costCentreShortName: any) {
    const url = environment.apiURL+"/costcentre/update/project";

    return this.http.post(url, {
      "projectShortName": projectShortName,
      "costCentreShortName": costCentreShortName
    })
      .pipe(
        catchError(this.util.handleAPIError)
      );
  }

  updateProjectCandidates(selectedCandidateMyTasks: any, costCentre: any) {
    const url = environment.apiURL+"/costcentre/update/projectcandidate";

    return this.http.post(url, {
      "projectCandidateMyTasks": selectedCandidateMyTasks,
      "costCentreId": costCentre
    })
      .pipe(
        catchError(this.util.handleAPIError)
      );
  }
}
