import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {delay, catchError, tap} from 'rxjs/operators';
import { isNgTemplate } from '@angular/compiler';
import { Candidate } from 'app/shared/models/candidate.model';
import{environment} from "environments/environment";

@Injectable()
export class CandidateService {

  candidates: Candidate[];
  constructor(
    private http: HttpClient,
  ) {

  }

  //******* Implement your APIs ********
  getCandidates(
    filterText: string = "",
    sort_field = "",
    sort_asc: boolean = true,
    startPageNo: number = 1,
    recordsPerPage: number = 50): Observable<any> {

    let params = {
      filterText: filterText,
      sortByField: sort_field,
      sortByAsc: sort_asc,

      pagination:
      {
        currentPageNo: startPageNo < 0 ? 0 : startPageNo,  //Note: page no starting from 0!
        recordsPerPage: recordsPerPage < 0 ? 50 : recordsPerPage//this will be constant
      }
    };

    const url = environment.apiURL+"/candidate/list";

    return this.http.post(url, params).pipe(
      catchError(this.handleError)
    );
  }
  addCandidate(candidate: Candidate): Observable<any> {
    const url = environment.apiURL+"/candidate/create";
    candidate.clientAccountName = JSON.parse(localStorage.getItem("currentUser"))['username'];

    return this.http.post(url, candidate).pipe(
      catchError(this.handleError)
    );
  }

  updateCandidate(candidate: Candidate): Observable<any> {

    const url = environment.apiURL+"/candidate/edit";
    //candidate.clientAccountName=JSON.parse(localStorage.getItem("currentUser"))['username'];

    return this.http.post(url, candidate).pipe(
      catchError(this.handleError)
    );
  }

  removeCandidate(row) {
    let i = this.candidates.indexOf(row);
    this.candidates.splice(i, 1);
    return of(this.candidates.slice()).pipe(delay(1000));
  }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  getInstruments(myTaskHash: any): any {
    const url = environment.apiURL+"/project/instruments/list";
    //candidate.clientAccountName=JSON.parse(localStorage.getItem("currentUser"))['username'];

    return this.http.post(url, {
      "clientAccountName": "xxxxx",
      "myTaskHash": myTaskHash
    }).pipe(
      catchError(this.handleError)
    );
  }

  resetInstrument(myTaskHash: any, projectInstrumentId: any): Observable<any> {
    const url = environment.apiURL+"/project/instruments/resetAssessment";
    //candidate.clientAccountName=JSON.parse(localStorage.getItem("currentUser"))['username'];

    return this.http.post(url, {
      myTaskHash: myTaskHash,
      projectInstrumentId: projectInstrumentId
    }).pipe(
      catchError(this.handleError)
    );
  }

  // fetchAssessmentSession(myTaskHash: string, projectInstrumentId: number, assessmentLangCode: string) {
  //   const serviceUrl = environment.apiURL + '/mytasks/fetchMyTasksInstrument';
  //
  //   const fetchMyTasksInstrumentRequest = {
  //     myTaskHash: myTaskHash,
  //     projectInstrumentId: projectInstrumentId,
  //     assessmentLangCode: assessmentLangCode
  //   };
  //   return this.http.post(serviceUrl, fetchMyTasksInstrumentRequest).pipe(catchError(this.handleError));
  // }

  revokeMyTasksAccess(myTaskHash: any): Observable<any> {
    const url = environment.apiURL+"/project/instruments/revokeMyTasks";

    return this.http.post(url, {
      myTaskHash: myTaskHash
    }).pipe(
      catchError(this.handleError)
    );
  }

  reinstateMyTasksAccess(myTaskHash: any): Observable<any> {
    const url = environment.apiURL+"/project/instruments/reinstateMyTasks";

    return this.http.post(url, {
      myTaskHash: myTaskHash
    }).pipe(
      catchError(this.handleError)
    );
  }


  getProjects(candidateEmail): Observable<any> {
    const url = environment.apiURL+"/candidate/list/pcs";

    return this.http.post(url, {
      "candidateEmail": candidateEmail
    }).pipe(
      catchError(this.handleError)
    );
  }

  listTopNCohort(limit: number, projectId: number) {
    const url = environment.apiURL+"/candidate/listTopNCohort";

    return this.http.post(url, {
      "limit": limit,
      "projectId": projectId
    }).pipe(
      catchError(this.handleError)
    );
  }

  getMeritList(myTaskHashList, competencyIdList, presentationName, projectId, reportId, sortCol, sortBy): Observable<any> {
    const url = environment.apiURL+"/meritList/getMeritList";
    return this.http.post(url, {
      "myTaskHashList": myTaskHashList,
      "competencyIdList": competencyIdList,
      "presentationName": presentationName,
      "projectId": projectId,
      "reportShortName": reportId,
      "sortCol": sortCol,
      "sortBy": sortBy
    }).pipe(
      catchError(this.handleError)
    );
  }

  exportMeritList(myTaskHashList, competencyIdList, presentationName, projectId, reportId, sortCol, sortBy): Observable<any> {
    const url = environment.apiURL+"/meritList/exportMeritList";
    return this.http.post(url, {
      "myTaskHashList": myTaskHashList,
      "competencyIdList": competencyIdList,
      "presentationName": presentationName,
      "projectId": projectId,
      "reportShortName": reportId,
      "sortCol": sortCol,
      "sortBy": sortBy
    }, {responseType: 'blob'}).pipe(
      catchError(this.handleError)
    );
  }

  // getMeritListPresentationRuleContent(presentationName, projectId, kind): Observable<any> {
  //   const url = environment.reportal2URL+"/meritList/getMeritListPresentationRuleContent";
  //   return this.http.post(url, {
  //     "presentationName": presentationName,
  //     "projectId": projectId,
  //     "kind": kind
  //   }).pipe(
  //     catchError(this.handleError)
  //   );
  // }
}
