import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { AppLoaderComponent } from './app-loader.component';

@Injectable()
export class AppLoaderService {
  dialogRef: MatDialogRef<AppLoaderComponent>;
  constructor(private dialog: MatDialog) { }



  count = 0;

  public _open(title: string = 'Please wait') {
    this.dialogRef = this.dialog.open(AppLoaderComponent, {
      disableClose: true,
      width: '200px',
      data: { title: title }
    });
  }

  public _close() {
    if (this.dialogRef)
      this.dialogRef.close();
  }

}
