import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { UtilService } from '../services/util.service';
import { AppLoaderService } from '../services/app-loader/app-loader.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(
    private router: Router,
    private utilService: UtilService,
    private loader: AppLoaderService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (request.url.indexOf('exportMeritList') > 0) {
    //   return;
    // }

    let isShowLoader = request.headers.get('show-loader');
    request.headers.delete('show-loader');
    if (this.totalRequests == 0 && isShowLoader != 'false') {
      this.loader._open();
    }
    if (isShowLoader != 'false') {
      this.totalRequests++;
    }

    //REQUEST INTERCEPTOR: add authorization header with jwt token if available
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + currentUser.token
        }
      });

      if (null != currentUser.selectedClientUserAccountName) {
        if (request.body) {
          request.body.clientAccountName = currentUser.selectedClientUserAccountName;
        }

      }
    }

    //RESPONSE INTERCEPTOR: COPE WITH THE TOKEN RENEWAL SEAMLESSLY
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.decreaseRequests();
          if (event && event.body && event.body.newToken) {
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser) {
              currentUser.token = event.body.newToken;
              localStorage.setItem('currentUser', JSON.stringify(currentUser));
            }
          }
        }
        else if (event instanceof HttpErrorResponse) {
        }
        else {
        }
      },
        /*
         * the error status 401 or 403 (Not authorised access) shall be checked against the response header.
         * Once we got such error, we shall be able to detect it from the interceptor and redirect the users
         * to the login page.
         */
        error => {
          this.decreaseRequests();
          // http error handling
          console.error("[Error Response] => " + JSON.stringify(error));
          if (error && (error.status === 401 || error.status === 403)) {
            this.utilService.clearLocalStorage();
            this.router.navigate(["/login"]);
          }
          // throw error;
        })
    );
  }

  private decreaseRequests() {
    if (this.totalRequests > 0) {
      this.totalRequests--;
    }
    if (this.totalRequests === 0) {
      this.loader._close();
    }
  }
}
