import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from '../../services/theme.service';
import { Subscription } from "rxjs";
import { ProjectService } from 'app/shared/services/project.service';
import { BroadcasterService } from 'app/shared/services/broadcaster.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
// import PerfectScrollbar from 'perfect-scrollbar';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html'
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  // private sidebarPS: PerfectScrollbar;
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  currentUser = null;

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private projectService: ProjectService,
    private broadcasterService: BroadcasterService,
    private loader: AppLoaderService
  ) { }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.constructMenu();
    // this.broadcasterService.currentData.subscribe(event => {
    //   if (event == 'projects-changed') {
    //     this.constructProjectsMenu(true);
    //     this.constructArchivedProjectsMenu(true)
    //   }
    // });

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  constructProjectsMenu(shouldProjectsReload: boolean = false) {
    let projectsMenuItem = this.menuItems.filter(item => item.name == 'PROJECTS');
    projectsMenuItem[0].type = 'dropDown';
    projectsMenuItem[0].sub = [{
      name: 'Active Projects',
      state: 'projects/active'
    }, {
      name: 'Archived Projects',
      state: 'projects/archived'
    }];

    // return;
    //
    // projectsMenuItem[0].sub = [{
    //   name: 'Loading...',
    //   type: 'link'
    // }];
    //
    // this.projectService.getProjects(shouldProjectsReload, false)
    //   .subscribe((res: any) => {
    //     let projects = res.projectList;
    //     projectsMenuItem[0].sub = [];
    //     projects.map(project => {
    //       if (project.type !== 'PageUp') {
    //         projectsMenuItem[0].sub.push({
    //           name: project.name,
    //           type: 'dropDown',
    //           sub: [
    //             {
    //               name: 'Candidates',
    //               state: 'projects/' + project.shortName
    //             },
    //             {
    //               name: 'Configure project',
    //               state: 'projects/' + project.shortName + '/configure-project'
    //             },
    //             {
    //               name: 'Dashboard',
    //               state: 'projects/' + project.shortName + '/' + project.id + '/merit-list'
    //             },
    //             {
    //               name: 'Bulk Upload',
    //               state: 'projects/' + project.shortName + '/bulk-upload'
    //             }
    //           ]
    //         });
    //       } else {
    //         projectsMenuItem[0].sub.push({
    //           name: project.name,
    //           type: 'dropDown',
    //           sub: [
    //             {
    //               name: 'Candidates',
    //               state: 'projects/' + project.shortName
    //             },
    //             {
    //               name: 'Configure project',
    //               state: 'projects/' + project.shortName + '/configure-project'
    //             },
    //             {
    //               name: 'Dashboard',
    //               state: 'projects/' + project.shortName + '/' + project.id + '/merit-list'
    //             }
    //           ]
    //         });
    //       }
    //     });
    //   });
  }

  constructArchivedProjectsMenu(shouldProjectsReload: boolean = false) {
    let archivedProjectsMenuItem = this.menuItems.filter(item => item.name === 'Archived Projects');
    archivedProjectsMenuItem[0].type = 'dropDown';
    archivedProjectsMenuItem[0].sub = [];

    archivedProjectsMenuItem[0].sub.push({
      name: 'Loading...',
      type: 'link',
    });
    this.projectService.getArchivedProjects(shouldProjectsReload, false)
      .subscribe((res: any) => {
        let archivedProjects = res.projectList;
        archivedProjectsMenuItem[0].sub = [];
        archivedProjects.map(archivedProject => {
          archivedProjectsMenuItem[0].sub.push({
            name: archivedProject.name,
            type: 'dropDown',
            sub: [
              {
                name: 'Candidates',
                state: 'projects/archived/' + archivedProject.shortName
              },
              {
                name: 'Project Details',
                state: 'projects/archived/' + archivedProject.shortName + '/details'
              }
            ]
          });
        });
      });
  }

  private constructMenu() {
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem;
      // Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;
      // this.constructProjectsMenu();
      // this.constructArchivedProjectsMenu();
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {

    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe()
    }
  }

}
