import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string,       // Possible values: link/dropDown/icon/separator/extLink
  name?: string,      // Used as display text for item and title for separator type
  state?: string,     // Router state
  icon?: string,      // Material icon name
  tooltip?: string,   // Tooltip text
  disabled?: boolean, // If true, item will not be appeared in sidenav.
  sub?: IChildItem[], // Dropdown items
  badges?: IBadge[]
}
interface IChildItem {
  type?: string,
  name: string,       // Display text
  state?: string,     // Router state
  icon?: string,
  sub?: IChildItem[]
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [
    // {
    //   name: 'PROJECTS',
    //   type: 'dropDown',
    //   tooltip: 'Projects',
    //   icon: 'folder_open',
    // },
    {
      name: 'Projects',
      type: 'dropDown_new',
      icon: 'folder_open',
      sub: [
        {
          name: 'Active Projects',
          type: 'link',
          state: 'project-list/active'
        },
        {
          name: 'Archived Projects',
          type: 'link',
          state: 'project-list/archived'
        }
      ]
    },
    // {
    //   name: 'Archived Projects',
    //   type: 'dropDown',
    //   tooltip: 'Archived Projects',
    //   icon: 'archive',
    // },
    {
      name: 'CANDIDATES',
      type: 'link',
      tooltip: 'Candidates',
      icon: 'people_outline',
      state: 'candidates',
    },
    {
      name: 'Cost Centers',
      type: 'link',
      tooltip: 'Cost Centres',
      icon: 'chrome_reader_mode',
      state: 'cost-centres',
    },
    {
      name: 'Notifications',
      type: 'link',
      tooltip: 'Notifications',
      icon: 'notifications_none',
      state: 'notifications/email-events/invitaitons',
    }
    // {
    //   name: 'Data Extracts',
    //   type: 'link',
    //   icon: 'grid_on',
    //   state: 'data-extracts/list'
    // }
    // {
    //   name: 'Data Extracts',
    //   type: 'dropDown',
    //   icon: 'grid_on',
    //   state: 'data-extracts',
    //   sub: [
    //     {
    //       name: 'Extract Filter',
    //       type: 'link',
    //       state: 'list'
    //     },
    //     {
    //       name: 'Extract History',
    //       type: 'link',
    //       state: 'history'
    //     }
    //   ]
    // }
  ];

  constructor() {
    console.log(JSON.parse(localStorage.getItem('currentUser')).accredited);
    if (JSON.parse(localStorage.getItem('currentUser')).accredited === true) {
      this.iconMenu.push(
        {
          name: 'Data Extracts',
          type: 'link',
          icon: 'grid_on',
          state: 'data-extracts/list'
        }
      );
    }
  }




  // console.log(localStorage.getItem("currentUser"));


  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  // publishNavigationChange(menuType: string) {
  //   switch (menuType) {
  //     case 'separator-menu':
  //       this.menuItems.next(this.separatorMenu);
  //       break;
  //     case 'icon-menu':
  //       this.menuItems.next(this.iconMenu);
  //       break;
  //     default:
  //       this.menuItems.next(this.defaultMenu);
  //   }
  // }
}
