import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class PublicGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true; // Set this value dynamically

  constructor(private router: Router) {console.log(router);}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // The storage will have the authToken
    if (localStorage.getItem('currentUser')) {
      this.isAuthenticated = true;
    } else {
      this.isAuthenticated = false;
    }

    // exclude my report page auth
    if (state.url.indexOf('myreports') >= 0) {
      return true;
    }

    if (this.isAuthenticated) {
        this.router.navigate(['/candidates/list']);
        return false;
    }

    return true;
  }
}
