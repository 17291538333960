import { ErrorHandler, ChangeDetectorRef } from "@angular/core";

export class CustomErrorHandler implements ErrorHandler {
    handleError(error) {
        console.error(error);
        let debugCtx = error['ngDebugContext'];
        let changeDetectorRef = debugCtx && debugCtx.injector.get(ChangeDetectorRef);
        if (changeDetectorRef) changeDetectorRef.detach();
    }
}
