import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UtilService } from '../services/util.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private utilService: UtilService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                this.utilService.clearLocalStorage();

                let loginURL = '/sessions/signin';
                let rUser = localStorage.getItem('r-user');
                if(rUser){
                    loginURL = '/sessions/lockscreen'
                }

                if (this.router.url != '/sessions/signin') {
                    window.location.href = loginURL + '?returnurl=' + this.router.url;
                }
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }

}
