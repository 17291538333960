import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CostCentreService } from 'app/shared/services/domain/cost-centre.service';
import { NotificationService } from 'app/shared/services/notification.service';

@Component({
  selector: 'create-cost-centre-dialog',
  templateUrl: './create-cost-centre.component.html',
  styleUrls: ['./create-cost-centre.component.scss'],
})
export class CreateCostCentreDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateCostCentreDialogComponent>,
    private fb: FormBuilder,
    private costCentreService: CostCentreService,
    private notificationService: NotificationService
  ) {
    this.buildItemForm();

  }

  public formGroup: FormGroup;

  ngOnInit() {
    if (this.data.name != null) {
      this.formGroup.setValue({
        name: this.data.name,
        number: this.data.number,
        email: this.data.email,
        phone: this.data.phone
      })
    }
  }

  buildItemForm() {
    this.formGroup = this.fb.group({
      name: [{ value: '', disabled: (this.data.name == null ? false : true) }, Validators.required],
      number: ['', Validators.required],
      email: [''],
      phone: ['']
    })
  }

  submit() {
    let formData = this.formGroup.value;
    if (this.data != null) {
      formData.shortName = this.data.shortName;
    }

    this.costCentreService.createOrUpdate(formData)
      .subscribe((res: any) => {
        if (res.succeeded) {
          this.notificationService.showSuccessMessage('Entity created successfully.');
          this.dialogRef.close(res.costCentreShortName);
        }
        else {
          this.notificationService.showErrorMessage(res.messageToDisplay);
        }
      },
        err => {
          this.notificationService.showInternalServerError();
        });
  }

}
