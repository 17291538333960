import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import{environment} from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { ProjectProgress } from '../models/project-candiate.model';
import { UtilService } from './util.service';

@Injectable()
export class ProjectService {

  items: any[];
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private utilService: UtilService
  ) {

  }

  getActiveProjectList(): Observable<any> {
    const url = environment.apiURL + '/project/list';
    return this.http.post(url, {}).pipe(
      catchError(this.handleError)
    );
  }

  getArchivedProjectList(): Observable<any> {
    const url = environment.apiURL + '/project/list/archived';
    return this.http.post(url, {}).pipe(
      catchError(this.handleError)
    );
  }

  getProjects(shouldProjectsReload: boolean = false, showLoader: boolean = true): Observable<any> {

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser.selectedClientUserAccountName == null) {
      return of({ 'internalErrorMessage': null, 'succeeded': true, 'messageToDisplay': 'OK', 'newToken': null, 'projectList': [] });
    }

    const cookieName = 'projects';
    if (!shouldProjectsReload) {
      const projects = localStorage.getItem(cookieName);
      if (projects != null && projects != '') {
        return of(JSON.parse(projects));
      }
    }
    const params = {};
    const url = environment.apiURL + '/project/list';

    const httpOptions = showLoader ? {} : this.utilService.getNoLoaderHTTPHeader();

    return this.http.post(url, params, httpOptions)
      .pipe(tap((response: any) => {
        localStorage.setItem(cookieName, JSON.stringify(response));
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

  getArchivedProjects(shouldProjectsReload: boolean = false, showLoader: boolean = true): Observable<any> {

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser.selectedClientUserAccountName == null) {
      return of({ 'internalErrorMessage': null, 'succeeded': true, 'messageToDisplay': 'OK', 'newToken': null, 'projectList': [] });
    }

    const cookieName = 'archivedprojects';
    if (!shouldProjectsReload) {
      const projects = localStorage.getItem(cookieName);
      if (projects != null && projects != '') {
        return of(JSON.parse(projects));
      }
    }
    const params = {};
    const url = environment.apiURL + '/project/list/archived';

    const httpOptions = showLoader ? {} : this.utilService.getNoLoaderHTTPHeader();

    return this.http.post(url, params, httpOptions)
      .pipe(tap((response: any) => {
        localStorage.setItem(cookieName, JSON.stringify(response));
      }))
      .pipe(
        catchError(this.handleError)
      );
  }

  addItem(item): Observable<any> {
    item._id = Math.round(Math.random() * 10000000000).toString();
    this.items.unshift(item);
    return of(this.items.slice()).pipe(delay(1000));
  }


  updateItem(id, item) {
    this.items = this.items.map(i => {
      if (i._id === id) {
        return Object.assign({}, i, item);
      }
      return i;
    });
    return of(this.items.slice()).pipe(delay(1000));
  }
  removeItem(row) {
    const i = this.items.indexOf(row);
    this.items.splice(i, 1);
    return of(this.items.slice()).pipe(delay(1000));
  }

  allocateCandidateToProject(item): Observable<any> {
    // if (item.completeDate) {
    //   item.completeDate = item.completeDate.toISOString().substring(0, 10);
    // }
    const url = environment.apiURL + '/project/allocateCandidate';

    return this.http.post(url, item).pipe(
      catchError(this.handleError)
    );
  }

  getListCandidates(
    projectShortName: string = '',
    activated: -1 | 0 | 1 = 0,
    status: ProjectProgress = ProjectProgress.Pending,
    filterText: string = '',
    startPageNo: number = 1,
    recordsPerPage: number = 50,
    isSortAsc,
    selectedCostCentreFilterId = null
  ): Observable<any> {

    const params: any = {
      filterText: filterText,
      projectShortName: projectShortName,
      toggleStatus: {
        costCentreId: selectedCostCentreFilterId
      },
      sortByAsc: isSortAsc,
      sortByField: 'creationTime',
      pagination:
      {
        currentPageNo: startPageNo < 0 ? 0 : startPageNo,  //Note: page no starting from 0!
        recordsPerPage: recordsPerPage < 0 ? 50 : recordsPerPage//this will be constant
      }
    };

    if (status != ProjectProgress.All) params['toggleStatus']['status'] = status;
    if (activated != -1) params['toggleStatus']['activated'] = activated;

    const url = environment.apiURL + '/project/listProjectCandidates';

    return this.http.post(url, params).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  saveEndDate(isForever: any, endDate: Date, endHour: any, projectShortName: string, timeZone: string) {
    const url = environment.apiURL + '/project/settings/enddate';

    return this.http.post(url, {
      'projectShortName': projectShortName,
      'endDateString': endDate ? (this.datePipe.transform(endDate, 'dd/MM/yyyy') + '-' + endHour) : null,
      'forever': isForever,
      'timezone': timeZone
    }).pipe(
      catchError(this.handleError)
    );
  }

  getEndDate(projectShortName: string) {
    const url = environment.apiURL + '/project/settings/enddate';

    return this.http.post(url, {
      'projectShortName': projectShortName
    }).pipe(
      catchError(this.handleError)
    );
  }

  saveNotificationReminderPeriod(days: Number, hours: Number, projectShortName: string) {
    const url = environment.apiURL + '/project/settings/reminder';

    return this.http.post(url, {
      'projectShortName': projectShortName,
      'inDays': days,
      'inHours': hours
    }).pipe(
      catchError(this.handleError)
    );
  }

  getNotificationReminderPeriod(projectShortName: string) {
    const url = environment.apiURL + '/project/settings/reminder';

    return this.http.post(url, {
      'projectShortName': projectShortName
    }).pipe(
      catchError(this.handleError)
    );
  }

  getRecipients(projectShortName: string) {
    const url = environment.apiURL + '/project/settings/reportnotifyrecipients';

    return this.http.post(url, { 'projectShortName': projectShortName }).pipe(
      catchError(this.handleError)
    );
  }

  addNewRecipient(projectShortName, emails: string[]) {
    const url = environment.apiURL + '/project/settings/reportnotifyrecipients';

    return this.http.post(url, {
      'projectShortName': projectShortName,
      'recipientEmails': emails
    }).pipe(
      catchError(this.handleError)
    );
  }

  setManualReminder(projectShortName: string, myTaskHash: string): Observable<any> {
    const url = environment.apiURL + '/project/reminder/manual';

    return this.http.post(url, {
      'projectShortName': projectShortName,
      'mytaskHash': myTaskHash
    }).pipe(
      catchError(this.handleError)
    );
  }

  uploadLogo(projectShortName, rawData: any) {
    const url = environment.apiURL + '/project/settings/projecticon';

    return this.http.post(url, {
      'projectShortName': projectShortName,
      'file': rawData
    }).pipe(
      catchError(this.handleError)
    );
  }

  getNotificationLogos(projectShortName: string) {
    const url = environment.apiURL + '/project/settings/showprojecticon';

    return this.http.post(url, {
      'projectShortName': projectShortName
    }).pipe(
      catchError(this.handleError)
    );
  }

  getNotificationHistory(myTaskHash: string) {
    const url = environment.apiURL + `/project/${myTaskHash}/checkEmailHistory`;

    return this.http.post(url, {}).pipe(
      catchError(this.handleError)
    );
  }

  create(projectDetails, instrumentsData) {
    const url = environment.apiURL + '/project/create';

    return this.http.post(url, {
      ...projectDetails,
      instruments: instrumentsData
    }).pipe(
      catchError(this.handleError)
    );
  }

  getProjectDetails(projectShortName: string) {
    const url = environment.apiURL + '/project/listProjectDetails';

    return this.http.post(url, { 'projectShortName': projectShortName }).pipe(
      catchError(this.handleError)
    );
  }

  archiveProject(projectShortName) {
    const url = environment.apiURL + '/project/archive';

    return this.http.post(url, {
      'projectShortNames': [projectShortName],
      'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
    }).pipe(
      catchError(this.handleError)
    );
  }

  getAllProjectLevelEmails(eventType, reportType) {
    const url = environment.apiURL + '/notification/project/list';

    return this.http.post(url, {
      'eventType': eventType,
      'reportType': reportType
    }).pipe(
      catchError(this.handleError)
    );
  }

  addNewRecipientToProject(projectShortName, eventType, reportType, newEmail) {
    const url = environment.apiURL + '/notification/project/addRecipient';
    return this.http.post(url, {
      'projectShortName': projectShortName,
      'eventType': eventType,
      'reportType': reportType,
      'newEmail': newEmail
    }).pipe(
      catchError(this.handleError)
    );
  }
  addClientLevelEmail(eventType, reportType, newEmail){
    const url = environment.apiURL + '/notification/client/addRecipient';
    return this.http.post(url, {
      'eventType': eventType,
      'reportType': reportType,
      'newEmail': newEmail
    }).pipe(
      catchError(this.handleError)
    );
  }

  fetchClientLevelEmails(eventType, reportType) {
    const url = environment.apiURL + '/notification/client/list';
    return this.http.post(url, {
      'eventType': eventType,
      'reportType': reportType
    }).pipe(
      catchError(this.handleError)
    );
  }

  addEmailToAllProjectLevels(eventType, reportType, newEmail){
    const url = environment.apiURL + '/notification/project/addEmailToAllProjects';
    return this.http.post(url, {
      'eventType': eventType,
      'reportType': reportType,
      'newEmail': newEmail
    }).pipe(
      catchError(this.handleError)
    );
  }

  fetchProjectNamesByEmailAddress(eventType, reportType, newEmail){
    const url = environment.apiURL + '/notification/project/getProjectNames';
    return this.http.post(url, {
      'eventType': eventType,
      'reportType': reportType,
      'newEmail': newEmail
    }).pipe(
      catchError(this.handleError)
    );
  }

  deleteEmailFromAllProjects(eventType, reportType, newEmail) {
    const url = environment.apiURL + '/notification/project/deleteEmailFromAllProjects';
    return this.http.post(url, {
      'eventType': eventType,
      'reportType': reportType,
      'newEmail': newEmail
    }).pipe(
      catchError(this.handleError)
    );
  }

  deleteEmailFromProject(eventType, reportType, projectShortName, newEmail) {
    const url = environment.apiURL + '/notification/project/deleteEmailFromProject';
    return this.http.post(url, {
      'eventType': eventType,
      'reportType': reportType,
      'projectShortName': projectShortName,
      'newEmail': newEmail
    }).pipe(
      catchError(this.handleError)
    );
  }

  deleteEmailFromClient(eventType, reportType, newEmail){
    const url = environment.apiURL + '/notification/client/deleteRecipient';
    return this.http.post(url, {
      'eventType': eventType,
      'reportType': reportType,
      'newEmail': newEmail
    }).pipe(
      catchError(this.handleError)
    );
  }


  setIncludeOrgEmailToClient(eventType, reportType, includeOrgEmailFlag){
    const url = environment.apiURL + '/notification/client/setIncludeOrgEmail';
    return this.http.post(url, {
      'eventType': eventType,
      'reportType': reportType,
      'includeOrgEmail': includeOrgEmailFlag
    }).pipe(
      catchError(this.handleError)
    );
  }

  setIncludeOrgEmailToProject(eventType, reportType, projectShortName, includeOrgEmailFlag){
    const url = environment.apiURL + '/notification/project/setIncludeOrgEmail';
    return this.http.post(url, {
      'eventType': eventType,
      'reportType': reportType,
      'projectShortName': projectShortName,
      'includeOrgEmail': includeOrgEmailFlag
    }).pipe(
      catchError(this.handleError)
    );
  }

  // addNewRecipientToProject(eventType, reportType, projectShortName, newEmail) {
  //   const url = environment.apiURL+"/notification/project/addRecipient";
  //   return this.http.post(url, {
  //     "eventType": eventType,
  //     "reportType": reportType,
  //     "projectShortName": projectShortName,
  //     "newEmail": newEmail
  //   }).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  getAllUnsubscriptionList(timezone) {
    const url = environment.apiURL + '/notification/unsubscriptionList';
    return this.http.post(url, {
      'timezone': timezone
    }).pipe(
      catchError(this.handleError)
    );
  }
  // checkGlobalSuppressionList(recipientEmails) {
  //   const url = environment.apiEmailURL + '/11DE9E2D-E3A5-452E-AA3D-26495D48DEB2/suppression/get';
  //   return this.http.post(url, {
  //     'recipient_emails': recipientEmails
  //   }).pipe(
  //     catchError(this.handleError)
  //   );
  // }
  // removeGlobalSuppressionList(recipientEmails) {
  //   const url = environment.apiEmailURL + '/11DE9E2D-E3A5-452E-AA3D-26495D48DEB2/suppression/remove';
  //   return this.http.post(url, {
  //     'recipient_emails': recipientEmails
  //   }).pipe(
  //     catchError(this.handleError)
  //   );
  // }
  removeGlobalSuppressionListFromDataStore(email) {
    const url = environment.apiURL + '/notification/removeSuppression';
    return this.http.post(url, {
      'email': email
    }).pipe(
      catchError(this.handleError)
    );
  }
  updateOrgEmail(email) {
    const url = environment.apiURL + '/notification/updateOrgEmail';
    return this.http.post(url, {
      'newEmail': email
    }).pipe(
      catchError(this.handleError)
    );
  }
  getIncludeOrmEmailProjectName(eventType, reportType) {
    const url = environment.apiURL + '/notification/client/getIncludeOrgEmailProjectNames';
    return this.http.post(url, {
      'eventType': eventType,
      'reportType': reportType
    }).pipe(
      catchError(this.handleError)
    );
  }
}
