// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class BroadcasterService {

  private dataSource = new BehaviorSubject(null);
  currentData = this.dataSource.asObservable();

  constructor() { }

  changeData(newData: any) {
    this.dataSource.next(newData);
  }

}