import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { Config } from "./config";

@Injectable()
export class NotificationService {

    constructor(private snack: MatSnackBar) {
    }

    showErrorMessage(message: string) {
        this.snack.open(message, 'Close', { duration: Config.notification.snack.errorDuration });
        // this.snack.open(message, null, { 
        //     duration: Config.notification.snack.errorDuration,
        //     horizontalPosition: 'right',
        //     verticalPosition: 'top',
        //     panelClass: ['error']
        //  });
    }

    showSuccessMessage(message: string) {
        this.snack.open(message, 'Ok', { duration: Config.notification.snack.errorDuration });
        // this.snack.open(message, null, { 
        //     duration: Config.notification.snack.errorDuration,
        //     horizontalPosition: 'right',
        //     verticalPosition: 'top',
        //     panelClass: ['success']
        //  });
    }

    showInternalServerError() {
        this.snack.open("Internal server error. Please try again.", 'Ok', { duration: Config.notification.snack.errorDuration });
    }
}