import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule, MatCardModule, MatCheckboxModule, MatDialogModule, MatGridListModule, MatIconModule, MatListModule, MatMenuModule, MatOptionModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSnackBarModule, MatToolbarModule, MatTooltipModule, MatInputModule } from '@angular/material';
import { RouterModule } from "@angular/router";
import { TranslateModule } from '@ngx-translate/core';
import { CreateCostCentreDialogComponent } from 'app/views/cost-centres/dialogs/create-cost-centre/create-cost-centre.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from './components/header-side/header-side.component';
// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from './components/header-top/header-top.component';
// ALL TIME REQUIRED 
import { AdminLayoutComponent } from './components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { MultilineSnackBar } from './components/multiline-snack-bar/multiline-snack-bar.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SidebarSideComponent } from './components/sidebar-side/sidebar-side.component';
import { SidebarTopComponent } from './components/sidebar-top/sidebar-top.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ConfirmActionCommon } from './confirm-action/confirm-action';
import { DropdownAnchorDirective } from './directives/dropdown-anchor.directive';
import { DropdownLinkDirective } from './directives/dropdown-link.directive';
import { AppDropdownDirective } from './directives/dropdown.directive';
// DIRECTIVES
import { ScrollToDirective } from './directives/scroll-to.directive';
import { GetValueByKeyPipe } from './pipes/get-value-by-key.pipe';
import { InstrumentStatusPipe } from './pipes/instrument-status.pipe';
// PIPES
import { SAReportStatusPipe } from './pipes/sa-report-status.pipe';
import { AppComfirmVTwoComponent } from './services/app-confirm-v2/app-confirm.component';
import { AppConfirmVTwoService } from './services/app-confirm-v2/app-confirm.service';
import { AppComfirmComponent } from './services/app-confirm/app-confirm.component';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { AppLoaderComponent } from './services/app-loader/app-loader.component';
import { AppLoaderService } from './services/app-loader/app-loader.service';
import { AuthGuard } from './services/auth/auth.guard';
import { PublicGuard } from './services/auth/public.gauard';
import { BulkUploadService } from './services/bulk-upload.service';
import { CandidateService } from './services/candidate.service';
import { CookieService } from './services/cookie.service';
import { DataSetService } from './services/dataset.service';
import { ClientService } from './services/domain/client.service';
import { LayoutService } from './services/layout.service';
import { MyTaskTemplatesService } from './services/my-task-templates.service';
import { NavigationService } from "./services/navigation.service";
import { NotificationService } from './services/notification.service';
import { ProjectService } from './services/project.service';
import { ReportService } from './services/report-service';
import { RoutePartsService } from './services/route-parts.service';
// SERVICES
import { ThemeService } from './services/theme.service';
import { UtilService } from './services/util.service';
import { ShowErrorsModule } from './validators/show-errors/show-errors.module';











/* 
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// import { LandingPageService } from '../shared/services/landing-page.service';

const classesToInclude = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppComfirmVTwoComponent,
  AppLoaderComponent,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  GetValueByKeyPipe,
  MultilineSnackBar,
  InstrumentStatusPipe,
  ConfirmActionCommon,
  SAReportStatusPipe,
  CreateCostCentreDialogComponent
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatSnackBarModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatDialogModule,
    PerfectScrollbarModule,
    ShowErrorsModule,
    MatInputModule
  ],
  entryComponents: [AppComfirmComponent, AppComfirmVTwoComponent, AppLoaderComponent, MultilineSnackBar, ConfirmActionCommon, CreateCostCentreDialogComponent],
  providers: [
    ThemeService,
    LayoutService,
    NavigationService,
    RoutePartsService,
    AuthGuard,
    PublicGuard,
    AppConfirmService,
    AppConfirmVTwoService,
    AppLoaderService,

    DataSetService,
    CandidateService,
    ProjectService,
    ReportService,
    NotificationService,
    MyTaskTemplatesService,
    BulkUploadService,

    DatePipe,
    UtilService,
    CookieService,

    ClientService
  ],
  declarations: classesToInclude,
  exports: [
    classesToInclude,
    ShowErrorsModule
  ]
})
export class SharedModule { }
