import { Injectable } from '@angular/core';


@Injectable()
export class CookieService {

  constructor(
  ) {
  }

  createCookie(name, value, expireInMins) {
    var dateTimeNow = new Date();
    dateTimeNow.setTime(dateTimeNow.getTime() + (expireInMins * 60 * 1000));
    var expires = "expires=" + dateTimeNow.toUTCString();
    const cookieStr = name + "=" + value + ";" + expires + ";path=/";
    if (document.cookie) {
      document.cookie = document.cookie + ';' + cookieStr;
    }
    else {
      document.cookie = cookieStr;
    }
  }

  getCookie(name) {
    name = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  deleteCookie(name) {
    var timeInThePast = new Date(0);
    var expires = "expires=" + timeInThePast.toUTCString();
    document.cookie = name + "=;" + expires + ";path=/";
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      this.deleteCookie(name);
    }
  }

}
