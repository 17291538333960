import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = true; // Set this value dynamically

  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //The storage will have the authToken
    if (localStorage.getItem('currentUser')) {
      this.isAuthenticated = true;
    }
    else {
      this.isAuthenticated = false;
    }

    if (this.isAuthenticated) {
      return true
    }
    let loginURL = '/sessions/signin';
    let rUser = localStorage.getItem('r-user');
    if(rUser){
        loginURL = '/sessions/lockscreen'
    }
    this.router.navigate([loginURL]);
    return false;
  }
}
