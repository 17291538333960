// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // apiURL: 'http://localhost:8086',
  apiURL: 'https://netresult-platform.appspot.com',
  reportal2URL: 'https://dataprep2-dot-netresult-platform.appspot.com',
  MESSAGES: {
    INTERNAL_SERVER_ERROR: "Internal Server Error. Please try again."
  }
};
