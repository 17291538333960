import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "instrumentStatus",
  pure: false
})
export class InstrumentStatusPipe implements PipeTransform {

  transform(status: string): string {

    var instrumentStatusliterals = {
      'None': 'Not started',
      'InProgress': 'In progress',
      'Completed': 'Completed',
      'UiCompleted': 'Fetching Results',
      'Scheduled': 'Reset (Not Started)'
    }

    return (instrumentStatusliterals[status] || status)
  }
}
