import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { CandidateService } from 'app/shared/services/candidate.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Candidate } from 'app/shared/models/candidate.model';
import { NotificationService } from 'app/shared/services/notification.service';
import { ProjectService } from 'app/shared/services/project.service';

@Component({
  selector: 'confirm-action',
  templateUrl: './confirm-action.html',
  styleUrls: ['./confirm-action.scss'],
})
export class ConfirmActionCommon implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmActionCommon>,
    private loader: AppLoaderService,
    private notificationService: NotificationService,
    private projectService: ProjectService
  ) { }

  title;
  description; 
  successMessage; 

  ngOnInit() {
    this.title = this.data.title;
    this.description = this.data.description;
  }

}
