import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';

//Merge to v1 when full e2e testing is possible
@Component({
  selector: 'app-confirm-v2',
  template: `<h1 matDialogTitle>{{ data.title }}</h1>
            <div mat-dialog-content>{{ data.message }}</div>
            <div mat-dialog-actions class="mt-2">
              <button type="button" mat-flat-button color="basic" 
                (click)="dialogRef.close(false)">
                Cancel
              </button>
              &nbsp;
              <span fxFlex></span>
              <button type="button" mat-raised-button color="warn" (click)="dialogRef.close(true)">
                {{ data.confirmActionText || 'Confirm' }}
              </button>
            </div>`,
})
export class AppComfirmVTwoComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmVTwoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
}