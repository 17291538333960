import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponse } from "../../restdto/AuthResponse";
import { Observable } from "rxjs/Observable";
import{environment} from "../../../../environments/environment";
import { tap } from "rxjs/operators";
import { UtilService } from '../util.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }

  authResponse: AuthResponse;
  userLocalStorageKey = 'currentUser';

  login(username: string, password: string, rememberMe: boolean): Observable<AuthResponse> {
    if (!username || !password) {
      return;
    }

    let serviceUrl = environment.apiURL + "/auth/user";

    return this.http.post<AuthResponse>(serviceUrl, { username, password }, {
      "reportProgress": true,
      "responseType": "json",
      headers: {
        'show-loader': 'false'
      }
    }).pipe(tap((data: AuthResponse) => {
      this.authResponse = data;

      let storedClientDataStr = localStorage.getItem('selectedClient');
      if (storedClientDataStr && this.authResponse.selectedClientUserAccountName == null) {
        this.authResponse.selectedClientUserAccountName = JSON.parse(storedClientDataStr)[username];
        this.authResponse.isPartner = true;
      }
      localStorage.setItem("currentUser", JSON.stringify(this.authResponse));
      if (rememberMe) {
        localStorage.setItem('r-user', JSON.stringify({
          username: this.authResponse.username,
          image: this.authResponse.profileUrl,
          firstName: this.authResponse.firstname,
          lastName: this.authResponse.lastname,
          accredited: this.authResponse.accredited
        }));
      }
      else {
        localStorage.removeItem('r-user');
      }
      this.utilService.clearAllCachedData();
    }));
  }

  isCurrentUserAccredited(){
    var currentUser: any = JSON.parse(localStorage.getItem(this.userLocalStorageKey));
    return currentUser.accredited;
  }

  logout() {
    this.utilService.clearLocalStorage();
    this.utilService.clearAllCachedData();
    localStorage.removeItem('r-user');
  }

  requestToResetPassword(email) {
    const url = environment.apiURL+"/reset/password/request";
    let httpOptions: any = this.utilService.getNoLoaderHTTPHeader
    return this.http.post(url, email, {
      headers: new HttpHeaders({
        'show-loader': 'false'
      })
    });
  }

  resetPassword(hash, password) {
    const url = environment.apiURL+"/reset/password/apply";
    let httpOptions: any = this.utilService.getNoLoaderHTTPHeader
    return this.http.post(url, {
      "resetHash" : hash,
      "newPassword" : password
    }, {
      headers: new HttpHeaders({
        'show-loader': 'false'
      })
    });
  }
}
