import { Component, OnInit, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/shared/services/auth/authentication.service';
import { Router } from '@angular/router';
import { ClientService } from 'app/shared/services/domain/client.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { BroadcasterService } from 'app/shared/services/broadcaster.service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  currentLang = 'en';
  public availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }]
  public egretThemes;
  public layoutConf: any;
  currentUser: any = {};
  clients = [];
  showClientDropdownHidden = true;

  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private authService: AuthenticationService,
    private router: Router,
    private clientService: ClientService,
    private notificationService: NotificationService,
    private broadcasterService: BroadcasterService
  ) { }
  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang);
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.currentUser.selectedClientUserAccountName == null || this.currentUser.isPartner) {
      this.clientService.getClients()
        .subscribe(res => {
          if (res.succeeded) {
            this.clients = res.clientShortNames;
            this.clients.sort((a, b) => a.localeCompare(b));
          }
          else {
            this.notificationService.showErrorMessage(res.messageToDisplay)
          }
        },
          err => {
            this.notificationService.showErrorMessage('Internal Server Error: Client not Found');
          })
    }
    else {
      this.showClientDropdownHidden = false;
    }

  }

  setLang(e) {
    console.log(e)
    this.translate.use(this.currentLang);
  }
  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      }, { transitionClass: true })
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact'
    }, { transitionClass: true })

  }

  signout() {
    this.authService.logout();
    this.router.navigateByUrl('/sessions/signin');
  }

  changeClient(client) {
    if (this.currentUser.selectedClientUserAccountName == client) {
      return;
    }

    this.currentUser.selectedClientUserAccountName = client;
    this.currentUser.isPartner = true;
    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    //this.broadcasterService.changeData('projects-changed');
    localStorage.removeItem('archivedprojects');
    localStorage.removeItem('projects');
    window.location.href = '/candidates/list';
    let clientDataStr = localStorage.getItem('selectedClient');
    let clientData;
    clientDataStr == null? clientData = {}: clientData = JSON.parse(clientDataStr);
    clientData[this.currentUser.username] = client;
    localStorage.setItem('selectedClient', JSON.stringify(clientData));
  }
}
