import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CookieService } from './cookie.service';
import { HttpHeaders } from '@angular/common/http';

const LANGS_LOCAL_STORAGE_KEY = "Langs"

@Injectable()
export class UtilService {

  constructor(
    private cookieService: CookieService
  ) {
  }

  handleAPIError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }

  clearAllCachedData() {
    localStorage.removeItem(LANGS_LOCAL_STORAGE_KEY);
    this.cookieService.deleteAllCookies();
  }

  clearLocalStorage() {
    localStorage.removeItem(LANGS_LOCAL_STORAGE_KEY);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('projects');
    localStorage.removeItem('archivedprojects');
    localStorage.removeItem('clients');
  }

  getNoLoaderHTTPHeader() {
    return {
      headers: new HttpHeaders({
        'show-loader': 'false'
      })
    }
  }

  makeMatOverlayScrollable(){
    document.getElementsByClassName('cdk-global-overlay-wrapper')[0].classList.add('cdk-global-overlay-wrapper-scrollable');
  }
}
