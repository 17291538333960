export enum ProjectProgress {
  All = 'All',
  Pending = 'Pending',
  Created = 'Created',
  Completed = 'Completed',
  Review = 'In Review',
  Started = 'Started',
  InProgress = 'In Progress',
  PendingResults = 'Pending Results',
  Aborted = 'Aborted',
  Error = 'Error'
}

export class ProjectCandidate {
  id: string;
  myTaskUrl: string;
  activateStatus: boolean;
  progress: ProjectProgress;
  firstName: string;
  lastName: string;
  emailAddress: string;
  myTaskHash: string;
  isSelected: boolean;
}
